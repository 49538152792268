import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest, makeDeleteRequest } from '@/api/helpers';

export const getTariffsList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(API.tariffs.list + `?page=${pageCurrent}${itemsFilter}&${sort}${filter}`);

    return result.data.result.table;
};

export const createTariff = async (data: any) => {
    const result = await makeFormRequest(`${API.tariffs.id}create/`, data);

    return result;
};

export const getTariff = async (cardId: number | string) => {
    const result = await makeGetRequest(API.tariffs.id + `${cardId}/`);

    return result.data.result.item;
};

export const updateTariff = async (cardId: number | string, data: any) => {
    const result = await makeFormRequest(API.tariffs.id + `${cardId}/`, data);

    return result.data.result.item;
};

export const removeTariff = async (params: string): Promise<string> => {
    const result = await makeDeleteRequest(`${API.tariffs.id}${params}/`);

    return result?.data?.result?.status;
};
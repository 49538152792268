import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';

import { getTariffsList } from '@/admin/api/tariffs';

export const MODULE_NAME = 'tariffs';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class TariffsModule extends PageBaseModule {
    constructor(module: TariffsModule) {
        super(module);
        const page = new PageEntity();

        this.pageSettings = page.values;
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        const result = await getTariffsList(this.pageSettings.pageCurrent, sort, '', this.pageSettings.pageAmountItems);

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.prepareTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'title') {
                    cell = `
                        <a href="/tariffs/${row.id}" class="cp-table__item-link">
                            <span class="cp-table-column__accent">${cell}</span>
                        </a>
                    `;
                }

                if (i === 'allowToBuy') {
                    cell = cell ? 'Да' : 'Нет';
                }

                if (i === 'description') {
                    cell = `<span class="title-pre">${cell}</span>`
                }

                if (i === 'monthly' || i === 'weekly' || i === 'daily' || i === 'annual') {
                    cell = `<span class="title-pre">${cell || '–'}</span>`
                }

                if (i === '_actions') {
                    cell = [];
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        // table.titles.pop();

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: ['id', 'dateDepth'],
        } as TableInterface;
    }
}

export default getModule(TariffsModule);


import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

import TariffsModule from '@/admin/store/tariffs';

@Component({
    name: 'Tariffs',
    components: {
        Layout,
        FlexLayout,
        TitleReturn,
        TableBlock,
        AppHeaderMain,
    },
})
export default class Tariffs extends Vue {
    get settings() {
        return TariffsModule.pageSettings;
    }

    get paginationAction() {
        return TariffsModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: TariffsModule.updateItems,
            items: TariffsModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            TariffsModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        TariffsModule.getList();
    }

    async resetFilters() {
        await TariffsModule.clearSort();

        await TariffsModule.getList();
    }

    toAdd() {
        this.$router.push('/tariffs/create');
    }

    mounted(): void {
        TariffsModule.init();
    }

    beforeDestroy(): void {
        TariffsModule.reset();
    }
}
